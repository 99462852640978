<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <div class="innerPage-banner heightCalc py-4 whiteBG">
            <div class="container-fluid h-100 calHeight">
                <div class="row h-100">
                    <div class="col-12 col-xl-3">
                        <!-- SearchBar -->
                        <economic-search></economic-search>
                        <div class="mainOverview p-3"><!-- transparent -->
                            <div class="toggleTab text-center">
                                <ul class="customTab d-inline-flex justify-content-center align-items-center">
                                    <li><a :class="[{'active' : tab == 'Labour'}]" href="javascript:void(0)"
                                            @click="tab = 'Labour';drawLabourChart()">Labour</a></li>
                                    <li><a href="javascript:void(0)" :class="[{'active' : tab == 'Health'}]"
                                            @click="tab = 'Health';drawHealthChart()">Health</a></li>
                                </ul>
                            </div>
                            <div v-show="tab == 'Labour'">
                                <div id="indicator_labourChart" style="width: 100%; height: 220px"></div>
                            </div>
                            <div v-show="tab == 'Health'">
                                <!--  <div class="charIQ text-center my-3 d-none"><img class="max-100" src="assets/images/map14.png" alt="map" /></div> -->
                                <!-- <div id="healthChart" style="width: 100%; height: 500px"></div> -->
                                <div class="charIQ text-center d-flex my-3">
                                    <div id="indicator_healthChart" style="width: 100%; height: 500px"></div>
                                    <!-- <div class="rightValue smIcon" v-if="OtherData.length > 0">
                                        <div class="media d-flex ln-1 text-left" v-for="item ,index in OtherData" :key="index">
                                            <span class="flex-sharink-0 me-2 iconRate"><img src="assets/images/mid_con1.svg" alt="Generic image" /></span>
                                            <div class="media-body text-start">
                                                <span class="iconcolor mb-2 d-flex f-13">{{item.Title}}</span>
                                                <h3 class="m-0">{{item.LatestValue}} {{(item.Unit.toLowerCase() == 'percent') ? '%' : item.Unit}}</h3>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-9">
                        <div class="mainRightMap">
                            <economic-mainnavbar></economic-mainnavbar>
                            <div class="flexAuto">
                                <economic-map v-show="Object.keys(store.economicGDPData).length > 0"></economic-map>
                                <carousel class="fullOverView" :settings="settings" :breakpoints="breakpoints"
                                    v-if="tab == 'Labour' && tradeOtherData.length">
                                    <slide v-for="item,index in tradeOtherData" :key="index">
                                        <div class="d-flex align-items-center">
                                            <span class="flex-shrink-0 me-2 iconRate"><img
                                                    src="assets/images/exchange-rate.svg" alt="Generic image" /></span>
                                            <div class="flex-grow-1">
                                                <span class="iconcolor f-13">{{item.Title}}</span>
                                                <h3 class="m-0">{{item.LatestValue}}{{(item.Unit == 'percent') ? '%' :
                                                item.Unit}}</h3>
                                            </div>
                                        </div>
                                    </slide>
                                    <template #addons>
                                        <Navigation />
                                    </template>
                                </carousel>
                                <carousel class="fullOverView" :settings="settings" :breakpoints="breakpoints"
                                    v-if="tab == 'Health' && OtherData.length">
                                    <slide v-for="item,index in OtherData" :key="index">
                                        <div class="d-flex align-items-center">
                                            <span class="flex-shrink-0 me-2 iconRate"><img
                                                    src="assets/images/exchange-rate.svg" alt="Generic image" /></span>
                                            <div class="flex-grow-1">
                                                <span class="iconcolor f-13">{{item.Title}}</span>
                                                <h3 class="m-0">{{item.LatestValue}}{{(item.Unit == 'percent') ? '%' :
                                                item.Unit}}</h3>
                                            </div>
                                        </div>
                                    </slide>
                                    <template #addons>
                                        <Navigation />
                                    </template>
                                </carousel>
                            </div>
                        </div>
                    </div>
                    <!-- 
                    <div class="col-12">
                        <bottom-navbar></bottom-navbar>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import economicmainnavbar from "./files/economy_mainnavbar";
import economicmap from "./files/economy_map";
// import bottomnavbar from "./files/bottom_navbar";
import economicsearch from "./files/economy_search";
import bannerslider from "../../components/shared/banner_slider";
import { useMainStore } from "@/store";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as index from "@amcharts/amcharts5/index";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import { Carousel, Slide, Navigation, } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            tab: 'Labour',
            chartData: [],
            OtherData: [],
            tradeOtherData: [],
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                767: {
                    itemsToShow: 2,
                    snapAlign: 'center',
                },
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                1199: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
            },
        }
    },
    watch: {
        'store.economicGDPData'() {
            if (this.tab == 'Labour') {
                this.drawLabourChart()
            } else if (this.tab == 'Health') {
                this.drawHealthChart()
            }
        }
    },
    components: {
        bannerSlider: bannerslider,
        economicMap: economicmap,
        // bottomNavbar:bottomnavbar,
        economicSearch: economicsearch,
        economicMainnavbar: economicmainnavbar,
        Carousel,
        Slide,
        Navigation,

    },
    methods: {
        drawHealthChart() {
            this.chartData = [];
            this.OtherData = [];
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'indicator_healthChart');
            d?.dispose();
            //console.log('health',am5.registry)
            var root = am5.Root.new("indicator_healthChart");
            root.setThemes([
                am5themes_Animated.new(root)
            ]);
            var chart = root.container.children.push(am5percent.SlicedChart.new(root, {
                layout: root.verticalLayout
            }));

            var series = chart.series.push(am5percent.PictorialStackedSeries.new(root, {
                alignLabels: true,
                orientation: "vertical",
                valueField: "LatestValue",
                categoryField: "Title",
                svgPath: "M53.5,476c0,14,6.833,21,20.5,21s20.5-7,20.5-21V287h21v189c0,14,6.834,21,20.5,21 c13.667,0,20.5-7,20.5-21V154h10v116c0,7.334,2.5,12.667,7.5,16s10.167,3.333,15.5,0s8-8.667,8-16V145c0-13.334-4.5-23.667-13.5-31 s-21.5-11-37.5-11h-82c-15.333,0-27.833,3.333-37.5,10s-14.5,17-14.5,31v133c0,6,2.667,10.333,8,13s10.5,2.667,15.5,0s7.5-7,7.5-13 V154h10V476 M61.5,42.5c0,11.667,4.167,21.667,12.5,30S92.333,85,104,85s21.667-4.167,30-12.5S146.5,54,146.5,42 c0-11.335-4.167-21.168-12.5-29.5C125.667,4.167,115.667,0,104,0S82.333,4.167,74,12.5S61.5,30.833,61.5,42.5z"
            }));
            series.get("colors").set("colors", [
                am5.color(0x19885E),
                am5.color(0xFFAD61),
                // am5.color(0x20AC77),
                // am5.color(0x1DBD81),
                // am5.color(0x16C784),
                // am5.color(0x2ED696),
            ]);

            series.labelsContainer.set("width", 100);
            series.ticks.template.set("visible", false);
            series.labels.template.set("visible", false);
            if (Object.keys(this.store.economicGDPData).length && this.store.economicGDPData.Health && Object.keys(this.store.economicGDPData.Health).length) {
                this.chartData = this.store.economicGDPData['Health'][this.$route.query.country].filter(val => (val.Category == 'Coronavirus Cases' || val.Category == 'Coronavirus Deaths'))
                this.OtherData = this.store.economicGDPData['Health'][this.$route.query.country].filter(val => (val.Category != 'Coronavirus Cases' && val.Category != 'Coronavirus Deaths'))
                series.data.setAll(this.chartData);
                chart.appear(1000, 100);
            }
        },
        drawLabourChart() {
            this.tradeOtherData = []
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'indicator_labourChart');
            d?.dispose();
            //console.log('labour',am5.registry)
            var root = am5.Root.new("indicator_labourChart");
            root.setThemes([
                am5themes_Animated.new(root)
            ]);
            var data = [];
            root.interfaceColors.set("grid", am5.color(0xffffff));
            if (Object.keys(this.store.economicGDPData).length && this.store.economicGDPData.Labour && Object.keys(this.store.economicGDPData.Labour).length) {
                data = this.store.economicGDPData['Labour'][this.$route.query.country].filter(val => val.Unit == 'percent');
                this.tradeOtherData = this.store.economicGDPData['Labour'][this.$route.query.country].filter(val => val.Unit != 'percent');
            }
            root.setThemes([
                am5themes_Animated.new(root)
            ]);
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                layout: root.verticalLayout
            }));
            var yAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                visible: false,
                categoryField: "Title",
                renderer: am5xy.AxisRendererY.new(root, {
                    cellStartLocation : 0.2,
                    cellEndLocation : 0.8
                }),
                // tooltip: am5.Tooltip.new(root, {
                //   themeTags: ["axis"],
                //   animationDuration: 200
                // })
            }));

            yAxis.data.setAll(data);
            chart.get("colors").set("colors", [
                am5.color(0x1D9C6C),
                am5.color(0x16C784),
                // am5.color(0x20AC77),
                // am5.color(0x1DBD81),
                // am5.color(0x16C784),
                // am5.color(0x2ED696),
            ]);

            var xAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                visible: false,
                min: 0,
                renderer: am5xy.AxisRendererX.new(root, {})
            }));

            var series0 = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Income",
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "PreviousValue",
                categoryYField: "Title",
                clustered: false,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{Title} : {PreviousValue} (Previous)"
                })
            }));

            series0.columns.template.setAll({
                height: am5.percent(100),
                width: '10px',
                tooltipY: 0
            });


            series0.data.setAll(data);


            var series1 = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Income",
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "LatestValue",
                categoryYField: "Title",
                clustered: false,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{Title} : {LatestValue}"
                })
            }));

            series1.columns.template.setAll({
                height: am5.percent(80),
                width: '10px',
                tooltipY: 0
            });

            series1.data.setAll(data);

            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineX.set("visible", false);
            cursor.lineY.set("visible", false);
            chart.appear(1000, 100);
            series0.appear();
            series1.appear();
        },
        callCountryEconomyData(country) {
            let formData = {
                "country": country,
                "group": ['Labour', 'Health']
            }
            this.store
                .getCountryEconomyData(formData, true)
                .then((response) => {
                })
                .catch((err) => console.log(err.message));
        },
    },
    mounted() {
        if (this.$route.query.country) {
            this.callCountryEconomyData(this.$route.query.country);
        }
        if (Object.keys(this.store.economicGDPData).length > 0) {
            am5.ready(() => {
                //this.drawLabourChart();
            });
        }
    }
}
</script>
<style>
#indicator_healthChart>div {
    display: none;
}

#indicator_healthChart>div:last-child {
    display: block !important;
}

#indicator_labourChart>div {
    display: none;
}

#indicator_labourChart>div:last-child {
    display: block !important;
}
</style>
